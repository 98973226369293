<template>
	<div class="app" :class="{ 'is-inpwa': $isPwa() }">
		<app-header fixed :class="{ 'is-inpwa': $isPwa() }" :key="`${keyVersion}`">
			<b-row class="ml-0">
				<BackInHistoryBtn v-if="$isPwa()" :key="`${keyVersion}2`" class="mr-3" />
				<!--<a class="navbar-brand" style="cursor: pointer" :href="`#/my-dashboard${version}`">-->
				<a class="navbar-brand" style="cursor: pointer">
					<img class="navbar-brand-full" src="img/brand/thegreenshot-brand.png" width="100" height="30" alt="TheGreenShot" />
					<img class="navbar-brand-minimized" src="img/brand/tgs-icon.svg" width="30" height="30" alt="TGS" />
				</a>
			</b-row>
			<b-row class="w-100" :style="`margin-top: ${$screen.width <= 900 ? '' : '-50px'}`">
				<div style="display: flex; align-items: center; justify-content: center; width: 100%; position: absolute">
					<div class="d-flex justify-content-center align-content-center fs-20 fw-700 text-color-rhapsody-in-blue">
						{{ FormMSG(851, 'Job Request') }}
					</div>
				</div>
				<div style="width: 100%" :class="{ 'mr-4': $screen.width <= 900 }">
					<div class="d-flex justify-content-end">
						<HeaderLanguageToggler :key="`${keyVersion}6`" />
					</div>
				</div>
			</b-row>
		</app-header>
		<div
			class="app-body"
			:class="{
				'is-pwa': $isPwa(),
				'is-ios': $isIOSDevice(),
				'is-android': $isAndroidDevice()
			}"
		>
			<main class="main">
				<div class="container-fluid pt-3" :key="`${keyVersion}23`">
					<router-view />
				</div>
			</main>
		</div>
	</div>
</template>

<script>
import { Header as AppHeader, SidebarToggler } from '@coreui/vue';
import { getParamVersion } from '@/shared/version';
import BackInHistoryBtn from '@/components/BackInHistoryBtn';
import languageMessages from '../mixins/languageMessages';
import HeaderLanguageToggler from '../components/HeaderLanguageToggler.vue';
import moment from 'moment-timezone';

export default {
	name: 'ContainerWithoutMenu',

	mixins: [languageMessages],

	components: {
		HeaderLanguageToggler,
		BackInHistoryBtn,
		AppHeader,
		SidebarToggler
	},

	computed: {
		keyVersion() {
			const version = getParamVersion();

			return version.replace('?v=', '');
		}
	},

	created() {
		moment.tz.setDefault('GMT'); // force using absolute date in GMT
	}
};
</script>
