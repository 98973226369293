var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app", class: { "is-inpwa": _vm.$isPwa() } },
    [
      _c(
        "app-header",
        {
          key: `${_vm.keyVersion}`,
          class: { "is-inpwa": _vm.$isPwa() },
          attrs: { fixed: "" },
        },
        [
          _c(
            "b-row",
            { staticClass: "ml-0" },
            [
              _vm.$isPwa()
                ? _c("BackInHistoryBtn", {
                    key: `${_vm.keyVersion}2`,
                    staticClass: "mr-3",
                  })
                : _vm._e(),
              _c(
                "a",
                {
                  staticClass: "navbar-brand",
                  staticStyle: { cursor: "pointer" },
                },
                [
                  _c("img", {
                    staticClass: "navbar-brand-full",
                    attrs: {
                      src: "img/brand/thegreenshot-brand.png",
                      width: "100",
                      height: "30",
                      alt: "TheGreenShot",
                    },
                  }),
                  _c("img", {
                    staticClass: "navbar-brand-minimized",
                    attrs: {
                      src: "img/brand/tgs-icon.svg",
                      width: "30",
                      height: "30",
                      alt: "TGS",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-row",
            {
              staticClass: "w-100",
              style: `margin-top: ${_vm.$screen.width <= 900 ? "" : "-50px"}`,
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center",
                    width: "100%",
                    position: "absolute",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-content-center fs-20 fw-700 text-color-rhapsody-in-blue",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(851, "Job Request")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  class: { "mr-4": _vm.$screen.width <= 900 },
                  staticStyle: { width: "100%" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("HeaderLanguageToggler", {
                        key: `${_vm.keyVersion}6`,
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "app-body",
          class: {
            "is-pwa": _vm.$isPwa(),
            "is-ios": _vm.$isIOSDevice(),
            "is-android": _vm.$isAndroidDevice(),
          },
        },
        [
          _c("main", { staticClass: "main" }, [
            _c(
              "div",
              {
                key: `${_vm.keyVersion}23`,
                staticClass: "container-fluid pt-3",
              },
              [_c("router-view")],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }